export const columns = [
  {
    title: "N°",
    key: "index",
    width: "4%",
    slots: { customRender: "rangepages" }
  },
  {
    title: "Documento",
    dataIndex: "documentoregistro",
    key: "documentoregistro"
  },
  {
    title: "Nombre",
    dataIndex: "nombre",
    key: "nombre"
  },
  {
    title: "Fecha Nac.",
    dataIndex: "fechanacimiento",
    key: "fechanacimiento"
  },
  {
    title: "Tipo Doc.",
    dataIndex: "abreviatura",
    key: "abreviatura"
  },
  {
    title: "ESTADO",
    dataIndex: "activo",
    key: "activo",
    slots: { customRender: "activo" }
  }
];

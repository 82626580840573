<template>
  <a-page-header @back="goBack" title="PERSONAL" :breadcrumb="{ routes }" class="px-0" />
  <div class="flex mb-4">
    <fieldset class="border border-solid border-gray-300 p-3 px-8">
      <legend class="text-sm text-left ml-2">FILTRO DE PERSONAL</legend>
      <PersonalFiltros @reloadFetch="reloadFetch" />
    </fieldset>
  </div>

  <a-table
    :columns="columns"
    :data-source="data"
    size="small"
    :pagination="false"
    rowKey="documentoregistro"
    :loading="isLoading"
    :rowClassName="
      (record, index) =>
        index % 2 === 0 ? 'cursor-pointer bg-white' : 'cursor-pointer bg-gray-100'
    "
  >
    <template #rangepages="{ index }">
      <span> {{ (curretPage - 1) * 10 + index + 1 }} </span>
    </template>

    <template #activo="{ text: activo }">
      <span>
        <a-tag :color="activo === '1' ? 'green' : 'volcano'">
          {{ activo === "1" ? "Activado" : "Desactivado" }}
        </a-tag>
      </span>
    </template>
  </a-table>

  <footer class="flex items-center justify-between pb-6 pt-5 border-t-2">
    <div class="flex">
      <a-pagination v-model:current="curretPage" v-model:pageSize="pageSize" :total="totalDocs" />

      <button class="ml-4" @click="reloadFetch">
        <ReloadOutlined />
      </button>
    </div>
    <p>Total de registros: {{ totalDocs }}</p>
  </footer>
</template>

<script>
import { ref, watch, onMounted } from "vue";
import { columns } from "./utilsPersonal";
import PersonalFiltros from "./PersonalFiltros.vue";
import { useStore } from "vuex";
import { ReloadOutlined } from "@ant-design/icons-vue";
import PersonalApi from "@/api/personal";
import router from "@/router";

export default {
  name: "Personal",
  components: {
    PersonalFiltros,
    ReloadOutlined
  },
  setup() {
    const pageSize = ref(10);
    const store = useStore();
    const curretPage = ref(1);
    const totalDocs = ref(null);
    const isVisible = ref(false);
    const data = ref([]);
    const isLoading = ref(false);

    // ------- onBack -------
    const goBack = () => router.back();

    const routes = [
      {
        path: "app",
        breadcrumbName: "Maestros"
      },
      {
        path: "/personal",
        breadcrumbName: "Personal"
      }
    ];

    // ------- trae datos de la api --------
    const fetchData = (currentPage = 1) => {
      const search = store.getters["personal/search"];
      const filter = store.getters["personal/filter"];

      isLoading.value = true;
      PersonalApi.getAll({ currentPage, search, filter })
        .then((response) => {
          console.log(response);
          data.value = response.data.docs;
          totalDocs.value = response.data.totalDocs;
        })
        .catch((err) => console.log(err))
        .finally(() => (isLoading.value = false));
    };

    onMounted(() => {
      fetchData();
    });

    // -------- trae datos al cambiar de pagina --------
    watch(curretPage, () => {
      fetchData(curretPage.value);
    });

    const reloadFetch = () => {
      fetchData(curretPage.value);
    };

    return {
      routes,
      isLoading,
      columns,
      data,
      pageSize,
      curretPage,
      totalDocs,
      isVisible,
      reloadFetch,
      goBack
    };
  }
};
</script>

<style></style>
